import { useMapStore } from "@/stores/mapStore"
import MapLoader from "./MapLoader"

const MapStatus = () => {
    const isLoading = useMapStore(state => state.isLoading)
    
    return (
        <MapLoader isLoading={isLoading} />
    )
}

export default MapStatus