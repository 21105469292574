import React, { ReactChild, useState } from 'react'
import styled from 'styled-components';
import BaseLayout from './BaseLayout';
import Controls, { BackBtn, HomeBtn, MenuBtn } from './Controls';
import Preloader from './../Preloader/Preloader';

type AppLayoutProps = {
    children: ReactChild | ReactChild[],
    title?: string,
    noIndex?: boolean,
    show?: boolean
}

const AppLayout: React.FC<AppLayoutProps> = ({ title, noIndex, children, show = false }) => {

    return (
            <BaseLayout title={title} noIndex={noIndex}>
                <Container>
                    {children}
                    <HomeBtn />
                    <MenuBtn />
                    <Controls />
                </Container>
                {/* <Preloader show={show} /> */}
            </BaseLayout>
    )
}

export default AppLayout

const Container = styled.div`
    width: 100%;
    position: relative;
    max-height: 100%;
    max-width: 100vw;
    overflow: hidden;
`