import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFound from '@/components/NotFound';
import LoginContainer from "@/components/pages/login/LoginPage";
import ProjectPage from "@/components/pages/project/ProjectPage";
import requiredAuth from '@/components/hoc/requiredAuth';
import ProtectedRoutes from "@/components/ProtectedRoutes";

const AppContainer = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginContainer />} />
                <Route element={<ProtectedRoutes />}>
                    <Route path="/project/:workspaceId/:projectId" element={<ProjectPage />} />
                </Route>
                <Route index element={<NotFound />} />
            </Routes>
        </Router>

    );
}

export default AppContainer