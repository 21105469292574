import { useMapStore } from '@/stores/mapStore'
import React, { useRef, useEffect, useState } from 'react'
import useImage from 'use-image'
import { useLayerView } from '@/api/hooks/useLayerView';
import { useGlobalStore } from '@/stores/globalStore'
import Map from './Map'
import styled from 'styled-components';
import { animated, useTransition } from 'react-spring';
import { easePoly } from 'd3-ease';
import { BackBtn } from '@/components/layout/Controls'
import RoomMap from './RoomMap'
import { useRoomStore } from '@/stores/roomStore'
import Loader from '@/components/Preloader/Loader';
import MapTooltip from './tooltip/MapTooltip';

export const useLayerImage = (layer?: number | null) => {
    const { layerView } = useLayerView(layer)
    const image = layerView?.image
    const [src, status] = useImage(image || '')

    if (!image || !layer) return {
        isLoading: false,
        image: null
    }

    return {
        isLoading: status === 'loading',
        image: src
    }

}

const MapContainer: React.FC = () => {
    const layer = useGlobalStore(state => state.layer)
    const home = useGlobalStore(state => state.home)
    const setLayer = useGlobalStore(state => state.setLayer)

    const { layerView } = useLayerView(home)

    return (
        <MapWrapper>
            <Map
                view={layerView?.view}
                polygons={layerView?.polygons}
            />
            <Room />
            <>
                {layer && (
                    <BackBtn onClick={() => setLayer(null)} />
                )}
            </>
            <MapTooltip />
        </MapWrapper>
    )
}


export default MapContainer


const Room = () => {
    const layer = useGlobalStore(state => state.layer)
    const setSize = useRoomStore(state => state.setSize)
    const { isLoading, image } = useLayerImage(layer)

    const { layerView } = useLayerView(layer)

    const transitions = useTransition(!!layer, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            duration: 300,
            easing: easePoly.exponent(2),
        },
    })

    const transitionsRoom = useTransition(image && !isLoading, {
        from: { opacity: 0, scale: 0.8 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 0.8 },
        config: {
            duration: 300,
            easing: easePoly.exponent(2),
        },
    })

    useEffect(() => {
        if (image) {
            setSize([image.width, image.height])
        }
    }, [image])

    return transitions((style, item) => item ? (
        <RoomWrapper style={style}>
            {transitionsRoom((style, item) => item ? (
                <AbsoluteWrapper style={style}>
                    <RoomMap
                        polygons={layerView?.polygons}
                        image={image}
                    />
                </AbsoluteWrapper>
            ) : (
                <AbsoluteWrapper style={style}>
                    <Loader />
                </AbsoluteWrapper>
            ))}
        </RoomWrapper>
    ) : '')
}

const AbsoluteWrapper = styled(animated.div)`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const RoomWrapper = styled(animated.div)`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4c4d4f;
`

const MapWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`