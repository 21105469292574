import useWindowSize from '@/hooks/useWindowSize'
import { useMapStore } from '@/stores/mapStore'
import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import { Stage } from 'react-konva'
import Konva from 'konva'
import { Vector2d } from 'konva/lib/types'
import { KonvaEventObject } from 'konva/lib/Node'

const scaleBy = 1.25

const isTouchEnabled =
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0;

// Do this to properly handle drag on touch devices.
Konva.hitOnDragEnabled = isTouchEnabled
const ZeroVector: Vector2d = { x: 0, y: 0 }

const MapStage: React.FC = ({ children }) => {
    const stageRef = useRef<any>(null)
    const lastCenter = React.useRef<Vector2d | null>(null)
    const lastDist = React.useRef<number>(0)

    const { width, height } = useWindowSize()

    // data selectors
    const setStage = useMapStore(state => state.setStage)
    const size = useMapStore(state => state.size)
    
    const scaleStage = React.useCallback(
        (
            stage: Konva.Stage,
            center: Vector2d,
            stageScale: number,
            centerDelta: Vector2d = ZeroVector
        ) => {
            const currentScale = stage.scaleX()

            // local coordinates of center point
            const localCenter = {
                x: (center.x - stage.x()) / currentScale,
                y: (center.y - stage.y()) / currentScale,
            }

            const newScale = limitScale(
                stageScale,
                width,
                height,
                size[0],
                size[1]
            )

            const newPos = {
                x: center.x - localCenter.x * newScale + centerDelta.x,
                y: center.y - localCenter.y * newScale + centerDelta.y,
            }

            const boundPos = boundFunc(newPos, newScale, width, height, size[0], size[1])

            stage.scale({ x: newScale, y: newScale })
            stage.position(boundPos)
            stage.batchDraw()
        },
        [width, height, size]
    )

    const handleScroll = React.useCallback(
        (event: KonvaEventObject<WheelEvent>) => {
            if (event.evt.defaultPrevented) {
                return
            }

            event.evt.preventDefault()
            const { currentTarget: stage } = event
            if (!(stage instanceof Konva.Stage)) {
                return
            }
            const scale = stage.scaleX()
            const newScale = event.evt.deltaY < 0 ? scale * scaleBy : scale / scaleBy

            scaleStage(
                stage,
                stage.getPointerPosition() ?? ZeroVector,
                newScale
            )
        },
        [scaleStage]
    )

    const resize = React.useCallback(() => {
        if (!size[0] || !size[1]) return

        const [stageWidth, stageHeight] = size
        const scaleX = width / stageWidth
        const scaleY = height / stageHeight
        const newScale = Number(Math.max(scaleX, scaleY))
        const stage = stageRef.current

        const newPos =  {
            x: -(size[0] * newScale - width) / 2,
            y: -(size[1] * newScale - height + 60) / 2
        }


        const pointX = Number(-(stageWidth * newScale - width) / 2)
        const pointY = Number(-(stageHeight * newScale - height) / 2)

        if (Number.isNaN(newScale) || Number.isNaN(pointX) || Number.isNaN(pointY)) return

        const resultScale = limitScale(newScale, width, height, stageWidth, stageHeight)

        stage.scale({ x: resultScale, y: resultScale })
        stage.position({
            x: pointX,
            y: pointY
        })
        stage.batchDraw()

    }, [stageRef, size, width, height])

    const onMouseDownHandler = React.useCallback((e) => {
        const container = e.target.getStage()?.container()

        if (container) {
            container.style.cursor = "grabbing"
        }
    }, [])

    const onMouseUpHandler = React.useCallback((e) => {
        const container = e.target.getStage()?.container()

        if (container) {
            container.style.cursor = "grab"
        }
    }, [])

    const handleTouchMove = React.useCallback(
        (event: KonvaEventObject<TouchEvent>) => {
          if (event.evt.defaultPrevented) {
            return
          }
    
          event.evt.preventDefault();
          const { currentTarget: stage } = event;
    
          if (!(stage instanceof Konva.Stage)) {
            return
          }
    
          if (event.evt.touches.length !== 2) {
            return
          }
    
          if (stage.isDragging()) {
            stage.stopDrag()
          }
    
          const [touch1, touch2]: any = event.evt.touches
          const p1 = { x: touch1.clientX, y: touch1.clientY }
          const p2 = { x: touch2.clientX, y: touch2.clientY }
          const newCenter = getCenter(p1, p2)
          const dist = getDistance(p1, p2)
    
          if (!lastCenter.current) {
            lastCenter.current = newCenter
          }
          if (!lastDist.current) {
            lastDist.current = dist
          }
    
          const centerDelta = {
            x: newCenter.x - lastCenter.current.x,
            y: newCenter.y - lastCenter.current.y,
          }

          const stageScale = (stage.scaleX() * (dist / lastDist.current))

          scaleStage(stage, lastCenter.current, stageScale, centerDelta)
    
          lastDist.current = dist
          lastCenter.current = newCenter
        },
        [scaleStage]
      )

    const multiTouchEnd = React.useCallback(
        (event: KonvaEventObject<TouchEvent>) => {
            lastCenter.current = null
            lastDist.current = 0
        },
        []
    )

    const multiTouchStart = React.useCallback(
        (event: KonvaEventObject<TouchEvent>) => {
            event.evt.preventDefault()

            const { currentTarget: stage } = event;

            if (event.evt.touches.length !== 2) {
                return;
            }

            stage.stopDrag()
        },
        []
    )

    
    const dragBoundFunc = React.useCallback(
        (pos) => boundFunc(pos, stageRef.current.scaleX(), width, height, size[0], size[1]),
        [size, stageRef, width, height]
    )


    useEffect(() => {
        resize()
    }, [size, width, height])

    useLayoutEffect(() => {
        if (stageRef.current) {
            setStage(stageRef.current)
        }
    }, [stageRef])

    return (
        <Stage
            ref={stageRef}
            width={width}
            height={height}
            draggable
            onWheel={handleScroll}
            onMouseDown={onMouseDownHandler}
            onMouseUp={onMouseUpHandler}
            onTouchMove={handleTouchMove}
            onTouchEnd={multiTouchEnd}
            onTouchStart={multiTouchStart}
            dragBoundFunc={dragBoundFunc}
        >
            {children}
        </Stage>

    )
}

export default MapStage

const getDistance = (p1: Vector2d, p2: Vector2d): number => Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
const getCenter = (p1: Vector2d, p2: Vector2d): Vector2d => ({
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
})
export const boundFunc = (pos, scale, width, height, stageWidth, stageHeight) => {
    const maxWidth = width - stageWidth * scale
    const maxHeight = height - stageHeight * scale

    let x = pos.x
    let y = pos.y

    if (x > 0) {
        x = 0
    }

    if (x < maxWidth) {
        x = maxWidth
    }

    if (y > 0) {
        y = 0
    }

    if (y < maxHeight) {
        y = maxHeight
    }

    return { x, y }
}

export const limitScale = (scale: number, width: number, height: number, stageWidth: number, stageHeight: number) => {
    const minWScale = width / stageWidth 
    const minHScale = height / stageHeight

    const minScale = Math.max(minWScale, minHScale)

    if (scale < minScale) {
        return minScale
    } else if (scale > 1) {
        return 1
    }
    
    return scale
}