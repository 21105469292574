import { ThemeProvider } from "styled-components"
import ConfirmDialog from "./components/ConfirmDialog"
import { GlobalStyles } from "./components/layout/GlobalStyles"
import { Reset } from "./components/layout/Reset"
import AppContainer from './containers/AppContainer'
import ReactQueryProvider from "./lib/react-query"
import ToastProvider from "./shared/toast/ToastProvider"
import blueTheme from './ui/blueTheme'

const App = () => {
  return (
    <ThemeProvider theme={blueTheme}>
      <ToastProvider>
        <ReactQueryProvider>
          {/* Reset styling */}
          <Reset />
          <GlobalStyles />

          {/* Main Application */}
          <AppContainer />
          <ConfirmDialog />
        </ReactQueryProvider>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default App