import { useMapStore } from '@/stores/mapStore'
import React from 'react'
import { Group, Layer } from 'react-konva'
import Polygon from './Polygon'
import { PolygonType } from '@/api/services/layer.service'
import MapStage from './MapStage'
import MapCoverLayer from './MapCoverLayer'
import Tooltip from './tooltip/Tooltip'

const Map: React.FC<{ view: any, polygons: PolygonType[] | undefined }> = ({ view, polygons = [] }) => {
    const [width, height] = useMapStore(state => state.size)

    return (
        <MapStage>
            <MapCoverLayer
                view={view}
            />
            <Layer>
                <Group>
                    {polygons.map(({ id, name, polygon }) => (
                        <Polygon
                            id={id}
                            key={id}
                            name={name}
                            width={width}
                            height={height}
                            polygon={polygon}
                        />
                    ))}
                </Group>
            </Layer>
            <Tooltip />
        </MapStage>
    )
}

export default Map