import React from 'react'

const MenuIcon = (props) => {
    return (
        <svg {...props} width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.890625 1.41913L16.1102 1.30206" stroke="#2F2F2F" />
            <path d="M1.24219 6.10199H11.3103" stroke="#2F2F2F" />
            <path d="M1.35938 10.902H13.1839" stroke="#2F2F2F" />
        </svg>
    )
}

export default MenuIcon