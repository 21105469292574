import { useQuery } from 'react-query';
import { useProject } from '@/hooks/useProject';
import { ProjectService } from '../services/project.service';
import { useToast } from '@/shared/toast/useToast';
import { extractTree } from '@/utils/helpers/tree.helpers';

export const useLayers = () => {
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()

    const { data, isLoading, refetch } = useQuery(
        ['tree', workspaceId, projectId],
        () => ProjectService.getTree({ workspaceId, projectId }),
        {
            enabled: !!workspaceId && !!projectId,
            select: ({ data }) => {
                const nodes = extractTree(data?.tree)
                const layer = nodes.find(node => node.parent === null)

                return { nodes, layer }
            },
            onError: ({ message }) => {
                enqueueToast({ title: 'Ошибка!', message }, { variant: 'error' })
            }
        }
    )

    return { layers: data, isLoading, refetch }
}