import React from 'react'

const PlusIcon = ({ color = '#828282', size = 24 }) => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.39551 0.950928V16.049" stroke="#2F2F2F" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M0.811523 8.46533H16.1879" stroke="#2F2F2F" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    )
}

export default PlusIcon