import React from 'react'

const MinusIcon = ({ color = '#828282' }) => {
    return (
        <svg width="17" height="2" viewBox="0 0 17 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.743164 1.46057H16.1032" stroke="#2F2F2F" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default MinusIcon