import create from 'zustand'

type RoomState = {
    stage: any
    size: [number, number]
    history: number[]
    setStage: (stage) => void
    addHistory: (layer: number) => void
    setHistory: (history: number[]) => void
    resetHistory: () => void
    setSize: (size: [number, number]) => void
}

export const useRoomStore = create<RoomState>(set => ({
    stage: null,
    size: [0, 0],
    history: [],
    addHistory: (layer) => set((state) => ({ history: [...state.history, layer] })),
    setHistory: (history) => set(() => ({ history })),
    resetHistory: () => set(() => ({ history: [] })),
    setStage: (stage) => set(() => ({ stage })),
    setSize: (size) => set(() => ({ size })),
}))

type MapPointType = {
    x: number
    y: number
}

interface OnRescaleProps {
    scale: number
    point: MapPointType
}