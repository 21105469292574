import React, { useEffect, useRef, useState } from 'react'
import { hexToRgba } from '@/lib/hexToRgb';
import { Group, Line } from 'react-konva';
import { PolygonData, PolygonType } from '@/api/layer';
import { useProjectStore } from '@/stores/projectStore';
import { useGlobalStore } from '@/stores/globalStore';
import { useRoomStore } from '@/stores/roomStore';
import { useMapStore } from '@/stores/mapStore';

interface PolygonElementProps {
    id: number
    name: string
    width: number
    height: number
    polygon: PolygonData
}

export const getMapCoord = (value: number, percent: number) => value * (percent / 100)

const Polygon: React.FC<PolygonElementProps> = ({ id, name, width, height, polygon }) => {
    const polygonRef = useRef<any>(null)
    
    const [fill, setFill] = useState(hexToRgba(polygon.fill, polygon.alpha / 100))
    const [stroke, setStroke] = useState(hexToRgba(polygon.stroke, polygon.hover / 100))
    const setLayer = useGlobalStore(state => state.setLayer)
    const addHistory = useRoomStore(state => state.addHistory)
    const setTooltip = useMapStore(state => state.setTooltip)

    const data = polygon.polygon.reduce((acc, val) => [...acc, getMapCoord(width, val[0]), getMapCoord(height, val[1])], [])

    const onMouseEnterHandler = (e) => {
        const container = e.target.getStage()?.container()

        if (container) {
            container.style.cursor = "pointer"
        }

        setFill(hexToRgba(polygon.fill, polygon.hover / 100))
        // setStroke(hexToRgba(polygon.stroke, polygon.hover / 100))
    }

    const onMouseLeaveHandler = (e) => {
        const container = e.target.getStage()?.container()

        if (container) {
            container.style.cursor = "default"
        }

        setFill(hexToRgba(polygon.fill, polygon.alpha / 100))
        // setStroke(hexToRgba(polygon.stroke, polygon.alpha / 100))
    }

    const onGroupMouseEnterHandler = React.useCallback((e) => {
        setTooltip(name || null)
    }, [setTooltip, name])

    const onGroupMouseLeaveHandler = React.useCallback((e) => {
        setTooltip(null)
    }, [setTooltip])

    const handleClick = (e) => {
        e.evt.preventDefault()
        setTooltip(null)
        addHistory(id)
        setLayer(id)
    }

    useEffect(() => {
        const instance = polygonRef.current
        instance.on("mouseenter", onGroupMouseEnterHandler)
        instance.on("mouseleave", onGroupMouseLeaveHandler)

        return () => {
            instance.off("mouseenter")
            instance.off("mouseleave")
        }
     })

    return (
        <Group
            ref={polygonRef}
        >
            <Line
                stroke={stroke}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler} 
                fill={fill}
                points={data}
                closed
                onClick={handleClick}
                onTap={handleClick}
            />
        </Group>
    )
}

export default Polygon