import { sizes } from '@/ui/media'
import { DefaultTheme } from 'styled-components'

export const blueTheme: DefaultTheme = {
    grid: {
        gutter: 8,
        breakpoints: sizes
    },
    menu: {
        color: "#fff",
        stroke: "#044D8C"
    },
    palette: {
        default: {
            main: '#fff',
            text: '#106386'
        },
        accent: {
            main: '#F8DC4D',
        },
        primary: {
            main: '#1A6DB2',
        },
        background: {
            primary: '#1A6DB2',
        },
        text: {
            primary: "#fff",
            secondary: "#000",
            label: '#fff',
            input: 'rgba(0, 0, 0, 0.25)',
        }
    },
}

export default blueTheme