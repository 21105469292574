import create from 'zustand'

type GlobalState = {
    isLoading: boolean
    setLoading: (isLoading: boolean) => void
    layer: number | null | undefined
    home: number | null
    setLayer: (layer: number | null | undefined) => void
    setHome: (home: number) => void
}

export const useGlobalStore = create<GlobalState>(set => ({
    isLoading: true,
    setLoading: (isLoading) => set(() => ({ isLoading })),
    layer: null,
    home: null,
    setLayer: (layer) => set(() => ({ layer })),
    setHome: (home) => set(() => ({ home })),
}))
