import React from 'react'
import { Field, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { RolesEnum, useUserStore } from '@/stores/userStore';
import { UserService } from '@/api/services/user.service';
import { FormGroup } from '@/ui/components/Form/FormGroup';
import { FormLabel } from '@/ui/components/Form/FormLabel';
import { InputField } from '@/ui/components/Field/Input';
import { Button } from '@/ui/components/Button/Button';

type LoginFormProps = {
    workspaceId: string | null | undefined
    projectId: string | null | undefined
}

const LoginForm: React.FC<LoginFormProps> = ({ workspaceId, projectId }) => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const setUser = useUserStore(state => state.setUser)
    const setRole = useUserStore(state => state.setRole)

    const handleLoginSubmit = async ({ login, password, workspaceId }: { login: string, password: string, workspaceId: string }, { setSubmitting, setErrors }) => {
        setSubmitting(true)
        try {

            const { data } = await UserService.auth({
                login,
                password,
                workspaceId: Number(workspaceId)
            })

            if (data?.ws_auth?.wst) {
                // localStorage.setItem('token', data.ws_auth.wst)
                setUser(data.me_data.ui)
                if (data.me_data.ui.role === RolesEnum.Admin) {
                    setRole(RolesEnum.Admin)
                }
                
                queryClient.resetQueries()
                
                navigate('/project/' + workspaceId + '/' + projectId)
            } else {
                alert('Unable to login')
            }
        } catch {
            alert('Unable to login')
        }
        setSubmitting(false)
    }

    if (!workspaceId) {
        return (
            <div>
                No workspace ID passed
            </div>
        )
    }

    return (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleLoginSubmit}
            initialValues={{
                login: '',
                password: '',
                workspaceId: workspaceId
            }}
        >
            {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                    <FormGroup>
                        <FormLabel $alternative>Логин</FormLabel>
                        <Field name="login" $transparent $fullWidth type="text" placeholder="Логин" component={InputField} />
                    </FormGroup>

                    <FormGroup>
                        <FormLabel $alternative>Пароль</FormLabel>
                        <Field name="password" $transparent $fullWidth type="password" placeholder="Пароль" component={InputField} />
                    </FormGroup>

                    <FormGroup>
                        <Button type="submit">Вход</Button>
                    </FormGroup>
                </form>
            )}
        </Formik>
    )
}

export default LoginForm