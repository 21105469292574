import axios from "axios";
import { projectAPI } from "./project";
import { userAPI } from './user';
import { bookingsAPI } from './bookings';
import { layerAPI } from './layer';

// export const BASE_DOMAIN = 'https://leader.ironstar.pw/'
export const BASE_DOMAIN = 'https://demoadmin.officescheme.ru/'


export const api = axios.create({
  withCredentials: false,
  baseURL: BASE_DOMAIN,
  headers: {
    'x-ws-common-auth': localStorage.getItem('token') || '',
  },
})

api.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('token') || ''
    config.headers = {
      'x-ws-common-auth': token
    }
    return config
  }
)

api.interceptors.response.use(
  async response => {
    const token = response.data?.ws_auth?.wst || '-invalid-'
    if (token !== '-invalid-') {
      localStorage.setItem('token', token)
    }

    return response
  }
)

export const API = {
  user: userAPI,
  projects: projectAPI,
  bookings: bookingsAPI,
  layer: layerAPI,
}


export enum StatusResponseEnum {
  Success = 'ok',
  Error = 'error',
  NotAuthorized = 'auth',
}

export type ApiResponseType = {
  status: StatusResponseEnum
  controller: string
  authorization: any,
  error_info: {
    message: string
    place: string
    trace: string
  }
  ws_auth: {
    wst: string
  }
  // project_access: string
  // project_user_data: {
  //     role: string
  //     plugin_data: {
  //         [key: string]: any
  //     }
  // },
}