import { StatusResponseEnum } from "@/api"
import { useMe } from "@/api/hooks/useMe"
import { useMetadata } from "@/api/hooks/useMetadata"
import { useGlobalStore } from "@/stores/globalStore"
import { RolesEnum, useUserStore } from "@/stores/userStore"
import { useEffect, useState } from "react"
import { Navigate, Outlet, useParams } from "react-router-dom"
import Preloader from '@/components/Preloader/Preloader';
import { ProjectService } from "@/api/services/project.service"
import { useToast } from "@/shared/toast/useToast"

function LoadingComponent() {
    return (
        <Preloader show={true} />
    )
}

const useAuth = () => {
    const { user } = useMe()
    const { workspaceId, projectId } = useParams<{ workspaceId: string, projectId: string }>()
    const { enqueueToast } = useToast()

    // selectors
    const setUser = useUserStore(state => state.setUser)
    const setAuthenticated = useUserStore(state => state.setAuthenticated)
    const setRole = useUserStore(state => state.setRole)

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const fetchData = async () => {


        try {
            const response = await ProjectService.getMetadata({ workspaceId: Number(workspaceId), projectId: Number(projectId) })
            if (response && response.data.status === StatusResponseEnum.Success) {
                setIsAuthenticated(true)
            }
        } catch (e) {
            enqueueToast({ title: 'Ошибка!', message: 'Не удалось загрузить метаданные проекта' }, { variant: 'error' })
        }

        setIsLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (!user) return
        setUser(user)

        if (user.role === RolesEnum.Admin) {
            setRole(RolesEnum.Admin)
        }

        setAuthenticated(true)
    }, [user])

    return { isAuthenticated, isLoading }
}

const ProtectedRoutes = () => {
    const { workspaceId, projectId } = useParams<{ workspaceId: string, projectId: string }>()


    const isAuth = useAuth()

    if (isAuth.isLoading) {
        return <LoadingComponent />
    }
    
    if (!isAuth.isAuthenticated) {
        return <Navigate to={`/login?workspace_id=${workspaceId}&project_id=${projectId}`} />
    }

    return <Outlet />
}

export default ProtectedRoutes