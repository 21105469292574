import React from 'react'

const CloseIcon = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5 15L1.20001 0.700013" stroke="#2F2F2F" strokeLinecap="round" />
            <path d="M1.2002 15L15.5002 0.700013" stroke="#2F2F2F" strokeLinecap="round" />
        </svg>
    )
}

export default CloseIcon