import { api, ApiResponseType } from "@/api";

export const UserService = {
    async auth({ login, password, workspaceId }: AuthDto) {
        const data = new FormData()
        data.append('login', login)
        data.append('password', password)
        data.append('workspace_id', String(workspaceId))

        return api.post<MeResponse>(`App/Auth/API?action=auth`, data)
    },
    async getMe(workspaceId: number) {
        return api.get<MeResponse>(`App/Auth/API?action=get_me&id=${workspaceId}`)
    },
    async getUsersList({ workspaceId, projectId, page = 0, perPage = 20, name }: UserListDto) {
        const data = new FormData()
        data.append('perpage', String(perPage))
        data.append('page', String(page))
        
        if (name !== '') {
            // data.append('filters[login]', String(filter))
            data.append('filters[display]', String(name))
        }

        return api.post<UsersListResponse>(`project/${workspaceId}/${projectId}/API?action=user_list`, data)
    },
}

interface AuthDto {
    workspaceId: number
    login: string
    password: string
}
interface UserListDto {
    workspaceId: number
    projectId: number
    page?: number
    perPage?: number
    name?: string
}
export interface UsersListResponse extends ApiResponseType {
    items: UserData[]
    total: number
}

export type UserData = {
    id: string
    login: string
    display: string
    updated_timestamp: string
}
export interface MeResponse extends ApiResponseType {
    me_data: MeData
}

export interface MeData {
    ui: IUser
    root_admin: true
}

export interface IUser {
    workspace_id: 0
    user_id: 0
    login: string
    display: string
    enabled: true
    role_id: string
    role: string
    projects: {
        [key: string]: string
    }
    class_version: string
}