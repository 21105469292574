import { useQuery } from 'react-query';
import { ProjectService } from '../services/project.service';
import { useProject } from '@/hooks/useProject';
import { useToast } from '@/shared/toast/useToast';

export const useMetadata = () => {
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()

    const { data, isLoading, refetch } = useQuery(
        ['metadata', workspaceId, projectId],
        () => ProjectService.getMetadata({ workspaceId, projectId }),
        {
            enabled: !!workspaceId && !!projectId,
            select: ({ data }) => ({ status: data.status, meta: data.metablock }),
            onError: () => {
                enqueueToast({ title: 'Ошибка!', message: 'Не удалось загрузить метаданные проекта' }, { variant: 'error' })
            }
        }
    )

    return { data, isLoading, refetch }
}