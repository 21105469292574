import React from 'react'
import styled, { css } from 'styled-components'
import create from "zustand"
import Close from './Close'
import Modal from './Modal/Modal'

const useConfirmDialogStore = create<ConfirmDialogStore>((set) => ({
    title: '',
    message: '',
    component: undefined,
    onSubmit: undefined,
    confirmBtnText: '',
    cancelBtnText: '',
    onClose: () => {
        set({
            onSubmit: undefined,
        })
    }
}))

export const confirmDialog = ({ title, message, component, onSubmit, confirmBtnText = 'Удалить', cancelBtnText = 'Отмена' }: ConfirmDialogProps) => {
    useConfirmDialogStore.setState({
        title,
        message,
        component,
        onSubmit,
        confirmBtnText,
        cancelBtnText
    })
}

const ConfirmDialog: React.FC = () => {
    const { message, onClose, onSubmit, component, title, confirmBtnText, cancelBtnText } = useConfirmDialogStore()

    const handleSubmitClick = (e) => {
        e.preventDefault()
        if (typeof onSubmit === 'function') {
            onSubmit()
        }
        onClose()
    }

    return (
        <Modal
            isOpen={Boolean(onSubmit)}
            onClose={onClose}
            maxWidth={377}
        >
            <Header>
                <Title>{title}</Title>
                <Close color="#000" onClick={onClose} />
            </Header>

            <div>
                {component ? component : (
                    <ConfirmText>
                        {message}
                    </ConfirmText>
                )}
            </div>

            <ActionsWrapper>
                <BookButton onClick={handleSubmitClick} type="button">{confirmBtnText}</BookButton>
                <CancelButton onClick={onClose} type="button">{cancelBtnText}</CancelButton>
            </ActionsWrapper>
        </Modal>
    )
}

export default ConfirmDialog

type ConfirmDialogStore = {
    title: string
    message?: string
    component?: JSX.Element
    onSubmit?: (...args) => void
    onClose: () => void
    confirmBtnText?: string
    cancelBtnText?: string
}

type ConfirmDialogProps = {
    title: string
    message?: string
    component?: JSX.Element
    onSubmit?: (...args) => void
    confirmBtnText?: string
    cancelBtnText?: string
}

const ConfirmText = styled.div`
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #000000;
    padding: 10px 0;
`

const ButtonBaseStyles = css`
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 1.6rem;
    line-height: 1.6rem;
    padding: 1.2rem;
    cursor: pointer;

    &:disabled {
        opacity: 0.7;
    }
`

export const BookButton = styled.button`
    ${ButtonBaseStyles};

    background: #044D8C;
    color: #F8DC4D;
    margin-right: 20px;
`

export const CancelButton = styled.button`
    ${ButtonBaseStyles};

    background: #BFBFBF;
    color: #fff;
`

export const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Title = styled.div`
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.4rem;
    color: #000000;
`