import React from 'react'
import styled from 'styled-components';
import MapContainer from './MapContainer';
import MapStatus from './MapStatus';

const CourseMap: React.FC = () => {
    return (
        <MapWrapper>
            <MapContainer />
            <MapStatus />
        </MapWrapper>
    )
}

export default CourseMap

const MapWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`