import React, { useState, useEffect } from 'react'
import { useMetadata } from '@/api/hooks/useMetadata';
import { useLayers } from '@/api/hooks/useLayers';
import { useGlobalStore } from '@/stores/globalStore';
import AppLayout from '@/components/layout/AppLayout';
import qs from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import requiredAuth from '@/components/hoc/requiredAuth';
import CourseMap from '@/shared/map/CourseMap';

const ProjectPage: React.FC = () => {
    return (
        <AppLayout>
            <CourseMap />
            <SideEffector />
        </AppLayout>
    )
}

export default ProjectPage


const SideEffector = () => {
    const home = useGlobalStore(state => state.home)
    const layer = useGlobalStore(state => state.layer)
    const setHome = useGlobalStore(state => state.setHome)
    const setLayer = useGlobalStore(state => state.setLayer)

    const { data } = useMetadata()
    const { layers } = useLayers()

    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const mapID = queryParams.map

    useEffect(() => {
        if (mapID) {
            setLayer(Number(mapID))
        }
    }, [])

    useEffect(() => {
        if (!home && layers?.layer) {
            setHome(Number(layers.layer.id))
        }
    }, [layers])

    useEffect(() => {
        const newQueries = { ...queryParams, map: layer }

        navigate({
            pathname: location.pathname,
            search: qs.stringify(newQueries, { sort: false, skipNull: true })
        })
    }, [layer])

    return null
}