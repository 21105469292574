import React from 'react'

const HomeIcon = () => {
    return (
        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.74854 19.7759V9.36722L10.4006 2.4281L18.362 9.36722V19.7759H2.74854Z" stroke="#2F2F2F" strokeLinejoin="round" />
            <path d="M7.92773 19.5933V11.6802H12.9518V19.5933" stroke="#2F2F2F" strokeLinejoin="round" />
        </svg>

    )
}

export default HomeIcon