import { useQuery } from 'react-query';
import { useProject } from '@/hooks/useProject';
import { useToast } from '@/shared/toast/useToast';
import { LayerService } from '../services/layer.service';

export const useLayerView = (layer?: number | null) => {
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()


    const { data, isLoading, refetch } = useQuery(
        ['layer_view_', layer],
        () => LayerService.getLayerView({
            workspaceId,
            projectId,
            layerId: Number(layer)
        }),
        {
            enabled: !!workspaceId && !!projectId && !!layer,
            select: ({ data }) => ({
                view: data.layer_view,
                image: LayerService.getLayerImage({
                    layerId: Number(layer),
                    imageName: data.layer_view.image.image_id,
                    extension: data.layer_view.image.extension
                }),
                polygons: data.layer_view.polygons
            }),
            onError: () => {
                enqueueToast({ title: 'Ошибка!', message: 'Не удалось загрузить данные о слое' }, { variant: 'error' })
            }
        }
    )

    return { layerView: data, isLoading, refetch }
}