import { api, ApiResponseType, BASE_DOMAIN } from "@/api";

export const LayerService = {
    async getLayerView({ workspaceId, projectId, layerId }: LayerViewDto) {
        const data = new FormData()
        data.append('id', `${layerId}`)

        return api.post<LayerViewResponse>(`project/${workspaceId}/${projectId}/API?action=get_layer_view`, data)
    },
    getLayerImage({ layerId, imageName, extension }: LayerImageDto) {
        return `${BASE_DOMAIN}media/layer/${layerId}/${imageName}.S!default.${extension}`
    }
}

interface LayerImageDto {
    imageName: string
    extension: string
    layerId: number
}

interface LayerViewDto {
    workspaceId: number
    projectId: number
    layerId: number
}

interface LayerViewResponse extends ApiResponseType {
	layer_view: ILayerView
}

export interface ILayerView {
	image: LayerImage
	polygons: PolygonType[]
	points: PointType[]
	map_node: {
		own_view: boolean
		label_size: number
		font_size: number
		color: string
		borderwidth: number
		wrap_text: boolean
		parent_id: number
	}
}
export interface PointType {
	raw_data?: any;
	workspace: number;
	metablock: number;
	project: number;
	id: number;
	name: string;
	parent: number;
	type_uid: string;
	type_name: string;
	x: number;
	y: number;
}

export interface PolygonData {
	stroke: string;
	fill: string;
	alpha: number;
	hover: number;
	polygon: number[][];
}

export interface PolygonType {
	own_view: boolean;
	id: number;
	name: string;
	type_name: string;
	polygon: PolygonData;
}

export interface LayerImage {
	sort: number;
	context_name: string;
	owner_id: string;
	image_id: string;
	mime: string;
	properties: any[];
	crops: any[];
	valid: boolean;
	extension: string;
	mime_safe: string;
}