const getChildren = (tree) => {
    if (!tree.childs || !tree.childs.length) {
        return []
    }
    return tree.childs
}

const extractFromTree = (tree) => {
    const nodeData = {
        id: tree.id,
        name: tree.name,
        parent: tree.parent_id
    }
    const children = getChildren(tree)

    if (!children.length) {
        return [nodeData]
    }

    const names = children.reduce(
        (acc, val) => [...acc, ...extractFromTree(val)]
    , [])

    return [nodeData, ...names]
}

export const extractTree = (arr): Array<TreeNodeData> => {
    if (!arr || !arr.length) return []
    return arr.reduce((acc, item) => [...acc, ...extractFromTree(item)], [])
}

export type TreeNodeData = {
    id: string
    name: string
    parent: string
}